<template>
  <ul class="pagination">
    <li class="pagination-item">
      <button type="button" class="btn" @click="onClickFirstPage" :disabled="isInFirstPage">First</button>
    </li>

    <li class="pagination-item">
      <button type="button" class="btn" @click="onClickPreviousPage" :disabled="isInFirstPage">Previous</button>
    </li>

    <!-- Visible Buttons Start -->

    <li v-for="(page, i) in pages" :key="i" class="pagination-item">
      <button type="button" :class="{ active: isPageActive(page.name) }" class="btn" @click="onClickPage(page.name)" :disabled="page.isDisabled">
        {{ page.name }}
      </button>
    </li>

    <!-- Visible Buttons End -->

    <li class="pagination-item">
      <button type="button" class="btn" @click="onClickNextPage" :disabled="isInLastPage">Next</button>
    </li>

    <li class="pagination-item">
      <button type="button" class="btn" @click="onClickLastPage" :disabled="isInLastPage">Last</button>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    maxVisibleButtons: {
      required: false,
      default: 3,
    },
    totalPages: {
      required: true,
    },
    perPage: {
      required: true,
    },
    currentPage: {
      required: true,
    },
  },
  computed: {
    isInFirstPage() {
      return this.currentPage === 1
    },
    isInLastPage() {
      return this.currentPage === this.totalPages
    },
    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1
      }

      // When on the last page
      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons
      }

      // When inbetween
      return this.currentPage - 1
    },
    pages() {
      const range = []

      for (let i = this.startPage; i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages); i++) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        })
      }

      return range
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit('pagechanged', 1)
    },
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1)
    },
    onClickPage(page) {
      this.$emit('pagechanged', page)
    },
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1)
    },
    onClickLastPage() {
      this.$emit('pagechanged', this.totalPages)
    },
    isPageActive(page) {
      return this.currentPage === page
    },
  },
}
</script>

<style>
.pagination {
  list-style-type: none;
  text-align: right;
  margin: 10px;
  padding-bottom: 10px;
}

.pagination-item {
  display: inline-block;
  margin: 0 4px;
}

.btn {
  padding: 0 8px;
  background-color: grey;
  color: white;
  border-radius: 5px;
  border: 1px solid transparent;
}

.active {
  background-color: #4aae9b;
  color: #ffffff;
}
</style>